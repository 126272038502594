import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';

const NikkeGuidesStagePenaltyPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Stage penalty</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_penalty.png"
            alt="Stage penalty"
          />
        </div>
        <div className="page-details">
          <h1>Stage penalty</h1>
          <h2>What is the Stage penalty system and how to abuse it.</h2>
          <p>
            Last updated: <strong>14/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
      <div className="page-content">
        <SectionHeader title="Stage penalty" />
        <p>
          One of the less known systems in NIKKE is the one related to the stage
          recommended power and how it affects your team. Most of the players
          will encounter the system only after they reach Chapter 4 or 5,
          because earlier the majority of the stages will have lower recommended
          power than their team has - as long as they will keep using the free
          resources to upgrade their teams.
        </p>
        <p>
          Still, the moment you will try to attack a stage where your power is
          lower than the recommended one, the Stage penalty system kicks in.
          What does it do?
        </p>
        <p>
          <strong>
            It decreases the stats of all your characters and also causes them
            to receive more damage.
          </strong>
        </p>
        <p>
          The Stage Penalty system is pretty popular in idle games as it
          encourages players to make their characters stronger by any means, so
          they can finally beat the stage there were stuck on.
        </p>
        <p>
          Also, the penalty increases the bigger the difference between your
          power and the recommended power is.
        </p>
        <SectionHeader title="How to calculate CP Deficit" />
        <p>
          The formula to calculate your CP Deficit is pretty straightforward:
        </p>
        <blockquote>
          <p>
            <strong>CP Deficit</strong> = 100 - (100 × your cp / stage cp)
          </p>
        </blockquote>
        <p>Here's an example:</p>
        <ul>
          <li>
            Your team power: <strong>129259</strong>
          </li>
          <li>
            Recommended stage power: <strong>192700</strong>
          </li>
          <li>Formula: 100 - (100 × 129259 / 192700)</li>
          <li>
            <strong>Your deficit is 33%</strong>.
          </li>
        </ul>
        <SectionHeader title="CP Deficit table" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>CP Deficit</th>
              <th>Stat penalty</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>49.91%</td>
              <td>90.00%</td>
            </tr>
            <tr>
              <td>49.81%</td>
              <td>77.10%</td>
            </tr>
            <tr>
              <td>49.71%</td>
              <td>77.00%</td>
            </tr>
            <tr>
              <td>49.61%</td>
              <td>76.90%</td>
            </tr>
            <tr>
              <td>49.51%</td>
              <td>76.80%</td>
            </tr>
            <tr>
              <td>49.41%</td>
              <td>76.70%</td>
            </tr>
            <tr>
              <td>49.31%</td>
              <td>76.60%</td>
            </tr>
            <tr>
              <td>49.21%</td>
              <td>76.50%</td>
            </tr>
            <tr>
              <td>49.11%</td>
              <td>76.40%</td>
            </tr>
            <tr>
              <td>49.01%</td>
              <td>76.30%</td>
            </tr>
            <tr>
              <td>48.91%</td>
              <td>76.20%</td>
            </tr>
            <tr>
              <td>48.81%</td>
              <td>76.10%</td>
            </tr>
            <tr>
              <td>48.71%</td>
              <td>76.00%</td>
            </tr>
            <tr>
              <td>48.61%</td>
              <td>75.90%</td>
            </tr>
            <tr>
              <td>48.51%</td>
              <td>75.80%</td>
            </tr>
            <tr>
              <td>48.41%</td>
              <td>75.70%</td>
            </tr>
            <tr>
              <td>48.31%</td>
              <td>75.60%</td>
            </tr>
            <tr>
              <td>48.21%</td>
              <td>75.50%</td>
            </tr>
            <tr>
              <td>48.11%</td>
              <td>75.40%</td>
            </tr>
            <tr>
              <td>48.01%</td>
              <td>75.30%</td>
            </tr>
            <tr>
              <td>47.91%</td>
              <td>75.20%</td>
            </tr>
            <tr>
              <td>47.81%</td>
              <td>75.10%</td>
            </tr>
            <tr>
              <td>47.71%</td>
              <td>75.00%</td>
            </tr>
            <tr>
              <td>47.61%</td>
              <td>74.90%</td>
            </tr>
            <tr>
              <td>47.51%</td>
              <td>74.80%</td>
            </tr>
            <tr>
              <td>47.41%</td>
              <td>74.70%</td>
            </tr>
            <tr>
              <td>47.31%</td>
              <td>74.60%</td>
            </tr>
            <tr>
              <td>47.21%</td>
              <td>74.50%</td>
            </tr>
            <tr>
              <td>47.11%</td>
              <td>74.40%</td>
            </tr>
            <tr>
              <td>47.01%</td>
              <td>74.30%</td>
            </tr>
            <tr>
              <td>46.91%</td>
              <td>74.20%</td>
            </tr>
            <tr>
              <td>46.81%</td>
              <td>74.10%</td>
            </tr>
            <tr>
              <td>46.71%</td>
              <td>74.00%</td>
            </tr>
            <tr>
              <td>46.61%</td>
              <td>73.90%</td>
            </tr>
            <tr>
              <td>46.51%</td>
              <td>73.80%</td>
            </tr>
            <tr>
              <td>46.41%</td>
              <td>73.70%</td>
            </tr>
            <tr>
              <td>46.31%</td>
              <td>73.60%</td>
            </tr>
            <tr>
              <td>46.21%</td>
              <td>73.50%</td>
            </tr>
            <tr>
              <td>46.11%</td>
              <td>73.40%</td>
            </tr>
            <tr>
              <td>46.01%</td>
              <td>73.30%</td>
            </tr>
            <tr>
              <td>45.91%</td>
              <td>73.20%</td>
            </tr>
            <tr>
              <td>45.81%</td>
              <td>73.10%</td>
            </tr>
            <tr>
              <td>45.71%</td>
              <td>73.00%</td>
            </tr>
            <tr>
              <td>45.61%</td>
              <td>72.90%</td>
            </tr>
            <tr>
              <td>45.51%</td>
              <td>72.80%</td>
            </tr>
            <tr>
              <td>45.41%</td>
              <td>72.70%</td>
            </tr>
            <tr>
              <td>45.31%</td>
              <td>72.60%</td>
            </tr>
            <tr>
              <td>45.21%</td>
              <td>72.50%</td>
            </tr>
            <tr>
              <td>45.11%</td>
              <td>72.40%</td>
            </tr>
            <tr>
              <td>45.01%</td>
              <td>72.30%</td>
            </tr>
            <tr>
              <td>44.91%</td>
              <td>72.20%</td>
            </tr>
            <tr>
              <td>44.81%</td>
              <td>72.10%</td>
            </tr>
            <tr>
              <td>44.71%</td>
              <td>72.00%</td>
            </tr>
            <tr>
              <td>44.61%</td>
              <td>71.90%</td>
            </tr>
            <tr>
              <td>44.51%</td>
              <td>71.80%</td>
            </tr>
            <tr>
              <td>44.41%</td>
              <td>71.70%</td>
            </tr>
            <tr>
              <td>44.31%</td>
              <td>71.60%</td>
            </tr>
            <tr>
              <td>44.21%</td>
              <td>71.50%</td>
            </tr>
            <tr>
              <td>44.11%</td>
              <td>71.40%</td>
            </tr>
            <tr>
              <td>44.01%</td>
              <td>71.30%</td>
            </tr>
            <tr>
              <td>43.91%</td>
              <td>71.20%</td>
            </tr>
            <tr>
              <td>43.81%</td>
              <td>71.10%</td>
            </tr>
            <tr>
              <td>43.71%</td>
              <td>71.00%</td>
            </tr>
            <tr>
              <td>43.61%</td>
              <td>70.90%</td>
            </tr>
            <tr>
              <td>43.51%</td>
              <td>70.80%</td>
            </tr>
            <tr>
              <td>43.41%</td>
              <td>70.70%</td>
            </tr>
            <tr>
              <td>43.31%</td>
              <td>70.60%</td>
            </tr>
            <tr>
              <td>43.21%</td>
              <td>70.50%</td>
            </tr>
            <tr>
              <td>43.11%</td>
              <td>70.40%</td>
            </tr>
            <tr>
              <td>43.01%</td>
              <td>70.30%</td>
            </tr>
            <tr>
              <td>42.91%</td>
              <td>70.20%</td>
            </tr>
            <tr>
              <td>42.81%</td>
              <td>70.10%</td>
            </tr>
            <tr>
              <td>42.71%</td>
              <td>70.00%</td>
            </tr>
            <tr>
              <td>42.61%</td>
              <td>69.90%</td>
            </tr>
            <tr>
              <td>42.51%</td>
              <td>69.80%</td>
            </tr>
            <tr>
              <td>42.41%</td>
              <td>69.70%</td>
            </tr>
            <tr>
              <td>42.31%</td>
              <td>69.60%</td>
            </tr>
            <tr>
              <td>42.21%</td>
              <td>69.50%</td>
            </tr>
            <tr>
              <td>42.11%</td>
              <td>69.40%</td>
            </tr>
            <tr>
              <td>42.01%</td>
              <td>69.30%</td>
            </tr>
            <tr>
              <td>41.91%</td>
              <td>69.20%</td>
            </tr>
            <tr>
              <td>41.81%</td>
              <td>69.10%</td>
            </tr>
            <tr>
              <td>41.71%</td>
              <td>69.00%</td>
            </tr>
            <tr>
              <td>41.61%</td>
              <td>68.90%</td>
            </tr>
            <tr>
              <td>41.51%</td>
              <td>68.80%</td>
            </tr>
            <tr>
              <td>41.41%</td>
              <td>68.70%</td>
            </tr>
            <tr>
              <td>41.31%</td>
              <td>68.60%</td>
            </tr>
            <tr>
              <td>41.21%</td>
              <td>68.50%</td>
            </tr>
            <tr>
              <td>41.11%</td>
              <td>68.40%</td>
            </tr>
            <tr>
              <td>41.01%</td>
              <td>68.30%</td>
            </tr>
            <tr>
              <td>40.91%</td>
              <td>68.20%</td>
            </tr>
            <tr>
              <td>40.81%</td>
              <td>68.10%</td>
            </tr>
            <tr>
              <td>40.71%</td>
              <td>68.00%</td>
            </tr>
            <tr>
              <td>40.61%</td>
              <td>67.90%</td>
            </tr>
            <tr>
              <td>40.51%</td>
              <td>67.80%</td>
            </tr>
            <tr>
              <td>40.41%</td>
              <td>67.70%</td>
            </tr>
            <tr>
              <td>40.31%</td>
              <td>67.60%</td>
            </tr>
            <tr>
              <td>40.21%</td>
              <td>67.50%</td>
            </tr>
            <tr>
              <td>40.11%</td>
              <td>67.40%</td>
            </tr>
            <tr>
              <td>40.01%</td>
              <td>67.30%</td>
            </tr>
            <tr>
              <td>39.91%</td>
              <td>67.20%</td>
            </tr>
            <tr>
              <td>39.81%</td>
              <td>67.10%</td>
            </tr>
            <tr>
              <td>39.71%</td>
              <td>67.00%</td>
            </tr>
            <tr>
              <td>39.61%</td>
              <td>66.90%</td>
            </tr>
            <tr>
              <td>39.51%</td>
              <td>66.80%</td>
            </tr>
            <tr>
              <td>39.41%</td>
              <td>66.70%</td>
            </tr>
            <tr>
              <td>39.31%</td>
              <td>66.60%</td>
            </tr>
            <tr>
              <td>39.21%</td>
              <td>66.50%</td>
            </tr>
            <tr>
              <td>39.11%</td>
              <td>66.40%</td>
            </tr>
            <tr>
              <td>39.01%</td>
              <td>66.30%</td>
            </tr>
            <tr>
              <td>38.91%</td>
              <td>66.20%</td>
            </tr>
            <tr>
              <td>38.81%</td>
              <td>66.10%</td>
            </tr>
            <tr>
              <td>38.71%</td>
              <td>66.00%</td>
            </tr>
            <tr>
              <td>38.61%</td>
              <td>65.90%</td>
            </tr>
            <tr>
              <td>38.51%</td>
              <td>65.80%</td>
            </tr>
            <tr>
              <td>38.41%</td>
              <td>65.70%</td>
            </tr>
            <tr>
              <td>38.31%</td>
              <td>65.60%</td>
            </tr>
            <tr>
              <td>38.21%</td>
              <td>65.50%</td>
            </tr>
            <tr>
              <td>38.11%</td>
              <td>65.40%</td>
            </tr>
            <tr>
              <td>38.01%</td>
              <td>65.30%</td>
            </tr>
            <tr>
              <td>37.91%</td>
              <td>65.20%</td>
            </tr>
            <tr>
              <td>37.81%</td>
              <td>65.10%</td>
            </tr>
            <tr>
              <td>37.71%</td>
              <td>65.00%</td>
            </tr>
            <tr>
              <td>37.61%</td>
              <td>64.90%</td>
            </tr>
            <tr>
              <td>37.51%</td>
              <td>64.80%</td>
            </tr>
            <tr>
              <td>37.41%</td>
              <td>64.70%</td>
            </tr>
            <tr>
              <td>37.31%</td>
              <td>64.60%</td>
            </tr>
            <tr>
              <td>37.21%</td>
              <td>64.50%</td>
            </tr>
            <tr>
              <td>37.11%</td>
              <td>64.40%</td>
            </tr>
            <tr>
              <td>37.01%</td>
              <td>64.30%</td>
            </tr>
            <tr>
              <td>36.91%</td>
              <td>64.20%</td>
            </tr>
            <tr>
              <td>36.81%</td>
              <td>64.10%</td>
            </tr>
            <tr>
              <td>36.71%</td>
              <td>64.00%</td>
            </tr>
            <tr>
              <td>36.61%</td>
              <td>63.90%</td>
            </tr>
            <tr>
              <td>36.51%</td>
              <td>63.80%</td>
            </tr>
            <tr>
              <td>36.41%</td>
              <td>63.70%</td>
            </tr>
            <tr>
              <td>36.31%</td>
              <td>63.60%</td>
            </tr>
            <tr>
              <td>36.21%</td>
              <td>63.50%</td>
            </tr>
            <tr>
              <td>36.11%</td>
              <td>63.40%</td>
            </tr>
            <tr>
              <td>36.01%</td>
              <td>63.30%</td>
            </tr>
            <tr>
              <td>35.91%</td>
              <td>63.20%</td>
            </tr>
            <tr>
              <td>35.81%</td>
              <td>63.10%</td>
            </tr>
            <tr>
              <td>35.71%</td>
              <td>63.00%</td>
            </tr>
            <tr>
              <td>35.61%</td>
              <td>62.90%</td>
            </tr>
            <tr>
              <td>35.51%</td>
              <td>62.80%</td>
            </tr>
            <tr>
              <td>35.41%</td>
              <td>62.70%</td>
            </tr>
            <tr>
              <td>35.31%</td>
              <td>62.60%</td>
            </tr>
            <tr>
              <td>35.21%</td>
              <td>62.50%</td>
            </tr>
            <tr>
              <td>35.11%</td>
              <td>62.40%</td>
            </tr>
            <tr>
              <td>35.01%</td>
              <td>62.30%</td>
            </tr>
            <tr>
              <td>34.91%</td>
              <td>62.20%</td>
            </tr>
            <tr>
              <td>34.81%</td>
              <td>62.10%</td>
            </tr>
            <tr>
              <td>34.71%</td>
              <td>62.00%</td>
            </tr>
            <tr>
              <td>34.61%</td>
              <td>61.90%</td>
            </tr>
            <tr>
              <td>34.51%</td>
              <td>61.80%</td>
            </tr>
            <tr>
              <td>34.41%</td>
              <td>61.70%</td>
            </tr>
            <tr>
              <td>34.31%</td>
              <td>61.60%</td>
            </tr>
            <tr>
              <td>34.21%</td>
              <td>61.50%</td>
            </tr>
            <tr>
              <td>34.11%</td>
              <td>61.40%</td>
            </tr>
            <tr>
              <td>34.01%</td>
              <td>61.30%</td>
            </tr>
            <tr>
              <td>33.91%</td>
              <td>61.20%</td>
            </tr>
            <tr>
              <td>33.81%</td>
              <td>61.10%</td>
            </tr>
            <tr>
              <td>33.71%</td>
              <td>61.00%</td>
            </tr>
            <tr>
              <td>33.61%</td>
              <td>60.90%</td>
            </tr>
            <tr>
              <td>33.51%</td>
              <td>60.80%</td>
            </tr>
            <tr>
              <td>33.41%</td>
              <td>60.70%</td>
            </tr>
            <tr>
              <td>33.31%</td>
              <td>60.60%</td>
            </tr>
            <tr>
              <td>33.21%</td>
              <td>60.50%</td>
            </tr>
            <tr>
              <td>33.11%</td>
              <td>60.40%</td>
            </tr>
            <tr>
              <td>33.01%</td>
              <td>60.30%</td>
            </tr>
            <tr>
              <td>32.91%</td>
              <td>60.20%</td>
            </tr>
            <tr>
              <td>32.81%</td>
              <td>60.10%</td>
            </tr>
            <tr>
              <td>32.71%</td>
              <td>60.00%</td>
            </tr>
            <tr>
              <td>32.61%</td>
              <td>59.90%</td>
            </tr>
            <tr>
              <td>32.51%</td>
              <td>59.80%</td>
            </tr>
            <tr>
              <td>32.41%</td>
              <td>59.70%</td>
            </tr>
            <tr>
              <td>32.31%</td>
              <td>59.60%</td>
            </tr>
            <tr>
              <td>32.21%</td>
              <td>59.50%</td>
            </tr>
            <tr>
              <td>32.11%</td>
              <td>59.40%</td>
            </tr>
            <tr>
              <td>32.01%</td>
              <td>59.30%</td>
            </tr>
            <tr>
              <td>31.91%</td>
              <td>59.20%</td>
            </tr>
            <tr>
              <td>31.81%</td>
              <td>59.10%</td>
            </tr>
            <tr>
              <td>31.71%</td>
              <td>59.00%</td>
            </tr>
            <tr>
              <td>31.61%</td>
              <td>58.90%</td>
            </tr>
            <tr>
              <td>31.51%</td>
              <td>58.80%</td>
            </tr>
            <tr>
              <td>31.41%</td>
              <td>58.70%</td>
            </tr>
            <tr>
              <td>31.31%</td>
              <td>58.60%</td>
            </tr>
            <tr>
              <td>31.21%</td>
              <td>58.50%</td>
            </tr>
            <tr>
              <td>31.11%</td>
              <td>58.40%</td>
            </tr>
            <tr>
              <td>31.01%</td>
              <td>58.30%</td>
            </tr>
            <tr>
              <td>30.91%</td>
              <td>58.20%</td>
            </tr>
            <tr>
              <td>30.81%</td>
              <td>58.10%</td>
            </tr>
            <tr>
              <td>30.71%</td>
              <td>58.00%</td>
            </tr>
            <tr>
              <td>30.61%</td>
              <td>57.90%</td>
            </tr>
            <tr>
              <td>30.51%</td>
              <td>57.80%</td>
            </tr>
            <tr>
              <td>30.41%</td>
              <td>57.70%</td>
            </tr>
            <tr>
              <td>30.31%</td>
              <td>57.60%</td>
            </tr>
            <tr>
              <td>30.21%</td>
              <td>57.50%</td>
            </tr>
            <tr>
              <td>30.11%</td>
              <td>57.40%</td>
            </tr>
            <tr>
              <td>30.01%</td>
              <td>57.30%</td>
            </tr>
            <tr>
              <td>29.91%</td>
              <td>57.20%</td>
            </tr>
            <tr>
              <td>29.81%</td>
              <td>57.10%</td>
            </tr>
            <tr>
              <td>29.71%</td>
              <td>57.00%</td>
            </tr>
            <tr>
              <td>29.61%</td>
              <td>56.90%</td>
            </tr>
            <tr>
              <td>29.51%</td>
              <td>56.80%</td>
            </tr>
            <tr>
              <td>29.41%</td>
              <td>56.70%</td>
            </tr>
            <tr>
              <td>29.31%</td>
              <td>56.60%</td>
            </tr>
            <tr>
              <td>29.21%</td>
              <td>56.50%</td>
            </tr>
            <tr>
              <td>29.11%</td>
              <td>56.40%</td>
            </tr>
            <tr>
              <td>29.01%</td>
              <td>56.30%</td>
            </tr>
            <tr>
              <td>28.91%</td>
              <td>56.20%</td>
            </tr>
            <tr>
              <td>28.81%</td>
              <td>56.10%</td>
            </tr>
            <tr>
              <td>28.71%</td>
              <td>56.00%</td>
            </tr>
            <tr>
              <td>28.61%</td>
              <td>55.90%</td>
            </tr>
            <tr>
              <td>28.51%</td>
              <td>55.80%</td>
            </tr>
            <tr>
              <td>28.41%</td>
              <td>55.70%</td>
            </tr>
            <tr>
              <td>28.31%</td>
              <td>55.60%</td>
            </tr>
            <tr>
              <td>28.21%</td>
              <td>55.50%</td>
            </tr>
            <tr>
              <td>28.11%</td>
              <td>55.40%</td>
            </tr>
            <tr>
              <td>28.01%</td>
              <td>55.30%</td>
            </tr>
            <tr>
              <td>27.91%</td>
              <td>55.20%</td>
            </tr>
            <tr>
              <td>27.81%</td>
              <td>55.10%</td>
            </tr>
            <tr>
              <td>27.71%</td>
              <td>55.00%</td>
            </tr>
            <tr>
              <td>27.61%</td>
              <td>54.90%</td>
            </tr>
            <tr>
              <td>27.51%</td>
              <td>54.80%</td>
            </tr>
            <tr>
              <td>27.41%</td>
              <td>54.70%</td>
            </tr>
            <tr>
              <td>27.31%</td>
              <td>54.60%</td>
            </tr>
            <tr>
              <td>27.21%</td>
              <td>54.50%</td>
            </tr>
            <tr>
              <td>27.11%</td>
              <td>54.40%</td>
            </tr>
            <tr>
              <td>27.01%</td>
              <td>54.30%</td>
            </tr>
            <tr>
              <td>26.91%</td>
              <td>54.20%</td>
            </tr>
            <tr>
              <td>26.81%</td>
              <td>54.10%</td>
            </tr>
            <tr>
              <td>26.71%</td>
              <td>54.00%</td>
            </tr>
            <tr>
              <td>26.61%</td>
              <td>53.90%</td>
            </tr>
            <tr>
              <td>26.51%</td>
              <td>53.80%</td>
            </tr>
            <tr>
              <td>26.41%</td>
              <td>53.70%</td>
            </tr>
            <tr>
              <td>26.31%</td>
              <td>53.60%</td>
            </tr>
            <tr>
              <td>26.21%</td>
              <td>53.50%</td>
            </tr>
            <tr>
              <td>26.11%</td>
              <td>53.40%</td>
            </tr>
            <tr>
              <td>26.01%</td>
              <td>53.30%</td>
            </tr>
            <tr>
              <td>25.91%</td>
              <td>53.20%</td>
            </tr>
            <tr>
              <td>25.81%</td>
              <td>53.10%</td>
            </tr>
            <tr>
              <td>25.71%</td>
              <td>53.00%</td>
            </tr>
            <tr>
              <td>25.61%</td>
              <td>52.90%</td>
            </tr>
            <tr>
              <td>25.51%</td>
              <td>52.80%</td>
            </tr>
            <tr>
              <td>25.41%</td>
              <td>52.70%</td>
            </tr>
            <tr>
              <td>25.31%</td>
              <td>52.60%</td>
            </tr>
            <tr>
              <td>25.21%</td>
              <td>52.50%</td>
            </tr>
            <tr>
              <td>25.11%</td>
              <td>52.40%</td>
            </tr>
            <tr>
              <td>25.01%</td>
              <td>52.30%</td>
            </tr>
            <tr>
              <td>24.91%</td>
              <td>52.20%</td>
            </tr>
            <tr>
              <td>24.81%</td>
              <td>52.10%</td>
            </tr>
            <tr>
              <td>24.71%</td>
              <td>52.00%</td>
            </tr>
            <tr>
              <td>24.61%</td>
              <td>51.90%</td>
            </tr>
            <tr>
              <td>24.51%</td>
              <td>51.80%</td>
            </tr>
            <tr>
              <td>24.41%</td>
              <td>51.70%</td>
            </tr>
            <tr>
              <td>24.31%</td>
              <td>51.60%</td>
            </tr>
            <tr>
              <td>24.21%</td>
              <td>51.50%</td>
            </tr>
            <tr>
              <td>24.11%</td>
              <td>51.40%</td>
            </tr>
            <tr>
              <td>24.01%</td>
              <td>51.30%</td>
            </tr>
            <tr>
              <td>23.91%</td>
              <td>51.20%</td>
            </tr>
            <tr>
              <td>23.81%</td>
              <td>51.10%</td>
            </tr>
            <tr>
              <td>23.71%</td>
              <td>51.00%</td>
            </tr>
            <tr>
              <td>23.61%</td>
              <td>50.90%</td>
            </tr>
            <tr>
              <td>23.51%</td>
              <td>50.80%</td>
            </tr>
            <tr>
              <td>23.41%</td>
              <td>50.70%</td>
            </tr>
            <tr>
              <td>23.31%</td>
              <td>50.60%</td>
            </tr>
            <tr>
              <td>23.21%</td>
              <td>50.50%</td>
            </tr>
            <tr>
              <td>23.11%</td>
              <td>50.40%</td>
            </tr>
            <tr>
              <td>23.01%</td>
              <td>50.30%</td>
            </tr>
            <tr>
              <td>22.91%</td>
              <td>50.20%</td>
            </tr>
            <tr>
              <td>22.81%</td>
              <td>50.10%</td>
            </tr>
            <tr>
              <td>22.71%</td>
              <td>50.00%</td>
            </tr>
            <tr>
              <td>22.61%</td>
              <td>49.90%</td>
            </tr>
            <tr>
              <td>22.51%</td>
              <td>49.80%</td>
            </tr>
            <tr>
              <td>22.41%</td>
              <td>49.70%</td>
            </tr>
            <tr>
              <td>22.31%</td>
              <td>49.60%</td>
            </tr>
            <tr>
              <td>22.21%</td>
              <td>49.50%</td>
            </tr>
            <tr>
              <td>22.11%</td>
              <td>49.40%</td>
            </tr>
            <tr>
              <td>22.01%</td>
              <td>49.30%</td>
            </tr>
            <tr>
              <td>21.91%</td>
              <td>49.20%</td>
            </tr>
            <tr>
              <td>21.81%</td>
              <td>49.10%</td>
            </tr>
            <tr>
              <td>21.71%</td>
              <td>49.00%</td>
            </tr>
            <tr>
              <td>21.61%</td>
              <td>48.90%</td>
            </tr>
            <tr>
              <td>21.51%</td>
              <td>48.80%</td>
            </tr>
            <tr>
              <td>21.41%</td>
              <td>48.70%</td>
            </tr>
            <tr>
              <td>21.31%</td>
              <td>48.60%</td>
            </tr>
            <tr>
              <td>21.21%</td>
              <td>48.50%</td>
            </tr>
            <tr>
              <td>21.11%</td>
              <td>48.40%</td>
            </tr>
            <tr>
              <td>21.01%</td>
              <td>48.30%</td>
            </tr>
            <tr>
              <td>20.91%</td>
              <td>48.20%</td>
            </tr>
            <tr>
              <td>20.81%</td>
              <td>48.10%</td>
            </tr>
            <tr>
              <td>20.71%</td>
              <td>48.00%</td>
            </tr>
            <tr>
              <td>20.61%</td>
              <td>47.90%</td>
            </tr>
            <tr>
              <td>20.51%</td>
              <td>47.80%</td>
            </tr>
            <tr>
              <td>20.41%</td>
              <td>47.70%</td>
            </tr>
            <tr>
              <td>20.31%</td>
              <td>47.60%</td>
            </tr>
            <tr>
              <td>20.21%</td>
              <td>47.50%</td>
            </tr>
            <tr>
              <td>20.11%</td>
              <td>47.40%</td>
            </tr>
            <tr>
              <td>20.01%</td>
              <td>47.30%</td>
            </tr>
            <tr>
              <td>19.91%</td>
              <td>47.20%</td>
            </tr>
            <tr>
              <td>19.81%</td>
              <td>47.10%</td>
            </tr>
            <tr>
              <td>19.71%</td>
              <td>47.00%</td>
            </tr>
            <tr>
              <td>19.61%</td>
              <td>46.90%</td>
            </tr>
            <tr>
              <td>19.51%</td>
              <td>46.80%</td>
            </tr>
            <tr>
              <td>19.41%</td>
              <td>46.70%</td>
            </tr>
            <tr>
              <td>19.31%</td>
              <td>46.60%</td>
            </tr>
            <tr>
              <td>19.21%</td>
              <td>46.50%</td>
            </tr>
            <tr>
              <td>19.11%</td>
              <td>46.40%</td>
            </tr>
            <tr>
              <td>19.01%</td>
              <td>46.30%</td>
            </tr>
            <tr>
              <td>18.91%</td>
              <td>46.20%</td>
            </tr>
            <tr>
              <td>18.81%</td>
              <td>46.10%</td>
            </tr>
            <tr>
              <td>18.71%</td>
              <td>46.00%</td>
            </tr>
            <tr>
              <td>18.61%</td>
              <td>45.90%</td>
            </tr>
            <tr>
              <td>18.51%</td>
              <td>45.80%</td>
            </tr>
            <tr>
              <td>18.41%</td>
              <td>45.70%</td>
            </tr>
            <tr>
              <td>18.31%</td>
              <td>45.60%</td>
            </tr>
            <tr>
              <td>18.21%</td>
              <td>45.50%</td>
            </tr>
            <tr>
              <td>18.11%</td>
              <td>45.40%</td>
            </tr>
            <tr>
              <td>18.01%</td>
              <td>45.30%</td>
            </tr>
            <tr>
              <td>17.91%</td>
              <td>45.20%</td>
            </tr>
            <tr>
              <td>17.81%</td>
              <td>45.10%</td>
            </tr>
            <tr>
              <td>17.71%</td>
              <td>45.00%</td>
            </tr>
            <tr>
              <td>17.61%</td>
              <td>44.90%</td>
            </tr>
            <tr>
              <td>17.51%</td>
              <td>44.80%</td>
            </tr>
            <tr>
              <td>17.41%</td>
              <td>44.70%</td>
            </tr>
            <tr>
              <td>17.31%</td>
              <td>44.60%</td>
            </tr>
            <tr>
              <td>17.21%</td>
              <td>44.50%</td>
            </tr>
            <tr>
              <td>17.11%</td>
              <td>44.40%</td>
            </tr>
            <tr>
              <td>17.01%</td>
              <td>44.30%</td>
            </tr>
            <tr>
              <td>16.91%</td>
              <td>44.20%</td>
            </tr>
            <tr>
              <td>16.81%</td>
              <td>44.10%</td>
            </tr>
            <tr>
              <td>16.71%</td>
              <td>44.00%</td>
            </tr>
            <tr>
              <td>16.61%</td>
              <td>43.90%</td>
            </tr>
            <tr>
              <td>16.51%</td>
              <td>43.80%</td>
            </tr>
            <tr>
              <td>16.41%</td>
              <td>43.70%</td>
            </tr>
            <tr>
              <td>16.31%</td>
              <td>43.60%</td>
            </tr>
            <tr>
              <td>16.21%</td>
              <td>43.50%</td>
            </tr>
            <tr>
              <td>16.11%</td>
              <td>43.40%</td>
            </tr>
            <tr>
              <td>16.01%</td>
              <td>43.30%</td>
            </tr>
            <tr>
              <td>15.91%</td>
              <td>43.20%</td>
            </tr>
            <tr>
              <td>15.81%</td>
              <td>43.10%</td>
            </tr>
            <tr>
              <td>15.71%</td>
              <td>43.00%</td>
            </tr>
            <tr>
              <td>15.61%</td>
              <td>42.84%</td>
            </tr>
            <tr>
              <td>15.51%</td>
              <td>42.70%</td>
            </tr>
            <tr>
              <td>15.41%</td>
              <td>42.56%</td>
            </tr>
            <tr>
              <td>15.31%</td>
              <td>42.43%</td>
            </tr>
            <tr>
              <td>15.21%</td>
              <td>42.29%</td>
            </tr>
            <tr>
              <td>15.11%</td>
              <td>42.15%</td>
            </tr>
            <tr>
              <td>15.01%</td>
              <td>42.01%</td>
            </tr>
            <tr>
              <td>14.91%</td>
              <td>41.88%</td>
            </tr>
            <tr>
              <td>14.81%</td>
              <td>41.74%</td>
            </tr>
            <tr>
              <td>14.71%</td>
              <td>41.60%</td>
            </tr>
            <tr>
              <td>14.61%</td>
              <td>41.46%</td>
            </tr>
            <tr>
              <td>14.51%</td>
              <td>41.33%</td>
            </tr>
            <tr>
              <td>14.41%</td>
              <td>41.19%</td>
            </tr>
            <tr>
              <td>14.31%</td>
              <td>41.05%</td>
            </tr>
            <tr>
              <td>14.21%</td>
              <td>40.91%</td>
            </tr>
            <tr>
              <td>14.11%</td>
              <td>40.78%</td>
            </tr>
            <tr>
              <td>14.01%</td>
              <td>40.64%</td>
            </tr>
            <tr>
              <td>13.91%</td>
              <td>40.50%</td>
            </tr>
            <tr>
              <td>13.81%</td>
              <td>40.36%</td>
            </tr>
            <tr>
              <td>13.71%</td>
              <td>40.23%</td>
            </tr>
            <tr>
              <td>13.61%</td>
              <td>40.09%</td>
            </tr>
            <tr>
              <td>13.51%</td>
              <td>39.95%</td>
            </tr>
            <tr>
              <td>13.41%</td>
              <td>39.81%</td>
            </tr>
            <tr>
              <td>13.31%</td>
              <td>39.68%</td>
            </tr>
            <tr>
              <td>13.21%</td>
              <td>39.54%</td>
            </tr>
            <tr>
              <td>13.11%</td>
              <td>39.40%</td>
            </tr>
            <tr>
              <td>13.01%</td>
              <td>39.26%</td>
            </tr>
            <tr>
              <td>12.91%</td>
              <td>39.13%</td>
            </tr>
            <tr>
              <td>12.81%</td>
              <td>38.99%</td>
            </tr>
            <tr>
              <td>12.71%</td>
              <td>38.85%</td>
            </tr>
            <tr>
              <td>12.61%</td>
              <td>38.71%</td>
            </tr>
            <tr>
              <td>12.51%</td>
              <td>38.58%</td>
            </tr>
            <tr>
              <td>12.41%</td>
              <td>38.44%</td>
            </tr>
            <tr>
              <td>12.31%</td>
              <td>38.30%</td>
            </tr>
            <tr>
              <td>12.21%</td>
              <td>38.16%</td>
            </tr>
            <tr>
              <td>12.11%</td>
              <td>38.03%</td>
            </tr>
            <tr>
              <td>12.01%</td>
              <td>37.89%</td>
            </tr>
            <tr>
              <td>11.91%</td>
              <td>37.75%</td>
            </tr>
            <tr>
              <td>11.81%</td>
              <td>37.61%</td>
            </tr>
            <tr>
              <td>11.71%</td>
              <td>37.48%</td>
            </tr>
            <tr>
              <td>11.61%</td>
              <td>37.34%</td>
            </tr>
            <tr>
              <td>11.51%</td>
              <td>37.20%</td>
            </tr>
            <tr>
              <td>11.41%</td>
              <td>37.06%</td>
            </tr>
            <tr>
              <td>11.31%</td>
              <td>36.93%</td>
            </tr>
            <tr>
              <td>11.21%</td>
              <td>36.79%</td>
            </tr>
            <tr>
              <td>11.11%</td>
              <td>36.65%</td>
            </tr>
            <tr>
              <td>11.01%</td>
              <td>36.51%</td>
            </tr>
            <tr>
              <td>10.91%</td>
              <td>36.38%</td>
            </tr>
            <tr>
              <td>10.81%</td>
              <td>36.24%</td>
            </tr>
            <tr>
              <td>10.71%</td>
              <td>36.10%</td>
            </tr>
            <tr>
              <td>10.61%</td>
              <td>35.96%</td>
            </tr>
            <tr>
              <td>10.51%</td>
              <td>35.83%</td>
            </tr>
            <tr>
              <td>10.41%</td>
              <td>35.69%</td>
            </tr>
            <tr>
              <td>10.31%</td>
              <td>35.55%</td>
            </tr>
            <tr>
              <td>10.21%</td>
              <td>35.41%</td>
            </tr>
            <tr>
              <td>10.11%</td>
              <td>35.28%</td>
            </tr>
            <tr>
              <td>10.01%</td>
              <td>35.14%</td>
            </tr>
            <tr>
              <td>9.91%</td>
              <td>35.00%</td>
            </tr>
            <tr>
              <td>9.81%</td>
              <td>34.90%</td>
            </tr>
            <tr>
              <td>9.71%</td>
              <td>34.80%</td>
            </tr>
            <tr>
              <td>9.61%</td>
              <td>34.70%</td>
            </tr>
            <tr>
              <td>9.51%</td>
              <td>34.59%</td>
            </tr>
            <tr>
              <td>9.41%</td>
              <td>34.47%</td>
            </tr>
            <tr>
              <td>9.31%</td>
              <td>34.36%</td>
            </tr>
            <tr>
              <td>9.21%</td>
              <td>34.23%</td>
            </tr>
            <tr>
              <td>9.11%</td>
              <td>34.11%</td>
            </tr>
            <tr>
              <td>9.01%</td>
              <td>33.98%</td>
            </tr>
            <tr>
              <td>8.91%</td>
              <td>33.84%</td>
            </tr>
            <tr>
              <td>8.81%</td>
              <td>33.70%</td>
            </tr>
            <tr>
              <td>8.71%</td>
              <td>33.56%</td>
            </tr>
            <tr>
              <td>8.61%</td>
              <td>33.41%</td>
            </tr>
            <tr>
              <td>8.51%</td>
              <td>33.26%</td>
            </tr>
            <tr>
              <td>8.41%</td>
              <td>33.10%</td>
            </tr>
            <tr>
              <td>8.31%</td>
              <td>32.94%</td>
            </tr>
            <tr>
              <td>8.21%</td>
              <td>32.78%</td>
            </tr>
            <tr>
              <td>8.11%</td>
              <td>32.61%</td>
            </tr>
            <tr>
              <td>8.01%</td>
              <td>32.44%</td>
            </tr>
            <tr>
              <td>7.91%</td>
              <td>32.26%</td>
            </tr>
            <tr>
              <td>7.81%</td>
              <td>32.08%</td>
            </tr>
            <tr>
              <td>7.71%</td>
              <td>31.90%</td>
            </tr>
            <tr>
              <td>7.61%</td>
              <td>31.71%</td>
            </tr>
            <tr>
              <td>7.51%</td>
              <td>31.51%</td>
            </tr>
            <tr>
              <td>7.41%</td>
              <td>31.31%</td>
            </tr>
            <tr>
              <td>7.31%</td>
              <td>31.11%</td>
            </tr>
            <tr>
              <td>7.21%</td>
              <td>30.91%</td>
            </tr>
            <tr>
              <td>7.11%</td>
              <td>30.70%</td>
            </tr>
            <tr>
              <td>7.01%</td>
              <td>30.48%</td>
            </tr>
            <tr>
              <td>6.91%</td>
              <td>30.26%</td>
            </tr>
            <tr>
              <td>6.81%</td>
              <td>30.04%</td>
            </tr>
            <tr>
              <td>6.71%</td>
              <td>29.81%</td>
            </tr>
            <tr>
              <td>6.61%</td>
              <td>29.58%</td>
            </tr>
            <tr>
              <td>6.51%</td>
              <td>29.34%</td>
            </tr>
            <tr>
              <td>6.41%</td>
              <td>29.10%</td>
            </tr>
            <tr>
              <td>6.31%</td>
              <td>28.86%</td>
            </tr>
            <tr>
              <td>6.21%</td>
              <td>28.61%</td>
            </tr>
            <tr>
              <td>6.11%</td>
              <td>28.36%</td>
            </tr>
            <tr>
              <td>6.01%</td>
              <td>28.10%</td>
            </tr>
            <tr>
              <td>5.91%</td>
              <td>27.84%</td>
            </tr>
            <tr>
              <td>5.81%</td>
              <td>27.58%</td>
            </tr>
            <tr>
              <td>5.71%</td>
              <td>27.31%</td>
            </tr>
            <tr>
              <td>5.61%</td>
              <td>27.03%</td>
            </tr>
            <tr>
              <td>5.51%</td>
              <td>26.76%</td>
            </tr>
            <tr>
              <td>5.41%</td>
              <td>26.47%</td>
            </tr>
            <tr>
              <td>5.31%</td>
              <td>26.19%</td>
            </tr>
            <tr>
              <td>5.21%</td>
              <td>25.90%</td>
            </tr>
            <tr>
              <td>5.11%</td>
              <td>25.60%</td>
            </tr>
            <tr>
              <td>5.01%</td>
              <td>25.30%</td>
            </tr>
            <tr>
              <td>4.91%</td>
              <td>25.00%</td>
            </tr>
            <tr>
              <td>4.81%</td>
              <td>24.69%</td>
            </tr>
            <tr>
              <td>4.71%</td>
              <td>24.38%</td>
            </tr>
            <tr>
              <td>4.61%</td>
              <td>24.07%</td>
            </tr>
            <tr>
              <td>4.51%</td>
              <td>23.75%</td>
            </tr>
            <tr>
              <td>4.41%</td>
              <td>23.42%</td>
            </tr>
            <tr>
              <td>4.31%</td>
              <td>23.09%</td>
            </tr>
            <tr>
              <td>4.21%</td>
              <td>22.76%</td>
            </tr>
            <tr>
              <td>4.11%</td>
              <td>22.42%</td>
            </tr>
            <tr>
              <td>4.01%</td>
              <td>22.08%</td>
            </tr>
            <tr>
              <td>3.91%</td>
              <td>21.74%</td>
            </tr>
            <tr>
              <td>3.81%</td>
              <td>21.39%</td>
            </tr>
            <tr>
              <td>3.71%</td>
              <td>21.04%</td>
            </tr>
            <tr>
              <td>3.61%</td>
              <td>20.68%</td>
            </tr>
            <tr>
              <td>3.51%</td>
              <td>20.32%</td>
            </tr>
            <tr>
              <td>3.41%</td>
              <td>19.95%</td>
            </tr>
            <tr>
              <td>3.31%</td>
              <td>19.58%</td>
            </tr>
            <tr>
              <td>3.21%</td>
              <td>19.21%</td>
            </tr>
            <tr>
              <td>3.11%</td>
              <td>18.83%</td>
            </tr>
            <tr>
              <td>3.01%</td>
              <td>18.44%</td>
            </tr>
            <tr>
              <td>2.91%</td>
              <td>18.06%</td>
            </tr>
            <tr>
              <td>2.81%</td>
              <td>17.66%</td>
            </tr>
            <tr>
              <td>2.71%</td>
              <td>17.27%</td>
            </tr>
            <tr>
              <td>2.61%</td>
              <td>16.87%</td>
            </tr>
            <tr>
              <td>2.51%</td>
              <td>16.47%</td>
            </tr>
            <tr>
              <td>2.41%</td>
              <td>16.06%</td>
            </tr>
            <tr>
              <td>2.31%</td>
              <td>15.65%</td>
            </tr>
            <tr>
              <td>2.21%</td>
              <td>15.23%</td>
            </tr>
            <tr>
              <td>2.11%</td>
              <td>14.81%</td>
            </tr>
            <tr>
              <td>2.01%</td>
              <td>14.38%</td>
            </tr>
            <tr>
              <td>1.91%</td>
              <td>13.95%</td>
            </tr>
            <tr>
              <td>1.81%</td>
              <td>13.52%</td>
            </tr>
            <tr>
              <td>1.71%</td>
              <td>13.08%</td>
            </tr>
            <tr>
              <td>1.61%</td>
              <td>12.64%</td>
            </tr>
            <tr>
              <td>1.51%</td>
              <td>12.19%</td>
            </tr>
            <tr>
              <td>1.41%</td>
              <td>11.74%</td>
            </tr>
            <tr>
              <td>1.31%</td>
              <td>11.29%</td>
            </tr>
            <tr>
              <td>1.21%</td>
              <td>10.83%</td>
            </tr>
            <tr>
              <td>1.11%</td>
              <td>10.37%</td>
            </tr>
            <tr>
              <td>1.01%</td>
              <td>9.90%</td>
            </tr>
            <tr>
              <td>0.91%</td>
              <td>9.43%</td>
            </tr>
            <tr>
              <td>0.81%</td>
              <td>8.95%</td>
            </tr>
            <tr>
              <td>0.71%</td>
              <td>8.48%</td>
            </tr>
            <tr>
              <td>0.61%</td>
              <td>7.99%</td>
            </tr>
            <tr>
              <td>0.51%</td>
              <td>7.50%</td>
            </tr>
            <tr>
              <td>0.41%</td>
              <td>7.01%</td>
            </tr>
            <tr>
              <td>0.31%</td>
              <td>6.51%</td>
            </tr>
            <tr>
              <td>0.21%</td>
              <td>6.01%</td>
            </tr>
            <tr>
              <td>0.11%</td>
              <td>5.51%</td>
            </tr>
            <tr>
              <td>0.01%</td>
              <td>5.00%</td>
            </tr>
            <tr>
              <td>0.00%</td>
              <td>0.0</td>
            </tr>
          </tbody>
        </Table>
        <p>
          <a
            href="https://docs.google.com/spreadsheets/d/1PaPU07AAZvECsSQeOhzMxue3q-sYXiKWclT3geaDJAM/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Source of data.
          </a>
        </p>
        <p>
          As you can see, even 0.01% power difference already cuts your damage
          output by 5% and also causes you to receive more damage (as both your
          HP and DEF stats have been lowered). The stat penalty progressively
          increases with the difference between your team's power and the
          recommended one - so keep that in mind.
        </p>
        <p>
          <strong>Important!</strong> The penalty is active in the Campaign
          (both normal and hard), Tribe Towers and Lost Sector. In other modes
          (EX Battles, Simulation Room, Interception and Coop) the stat penalty
          isn't applied.
        </p>
        <SectionHeader title="How to increase your team's power" />
        <p>
          Knowing that the penalty is so harsh when you're attacking stages
          above the team's power, here are all the things that impact your
          characters's power:
        </p>
        <ul>
          <li>
            <strong>Level</strong> - obviously leveling your characters gives
            you a lot of power, so this will be always the best way to make your
            team stronger,
          </li>
          <li>
            <strong>Rarity</strong> - characters with higher rarity have more
            base power than the lower rarity ones,
          </li>
          <ul>
            <li>
              Also, you get more overall power if you level your top 5
              characters equally, instead of making one of them stronger (like
              the DPS). So just keep leveling them one by one, so they are the
              same level,
            </li>
          </ul>
          <li>
            <strong>Limit break</strong> - limit breaking your characters by
            feeding dupes into them increases power by a lot,
          </li>
          <li>
            <strong>Equipment</strong> - gives a decent amount of power,
          </li>
          <ul>
            <li>And you can also upgrade it to gain even more power,</li>
          </ul>
          <li>
            <strong>Skills</strong> - leveling skills gives a minor increase to
            power,
          </li>
          <li>
            <strong>Recycling Room</strong> - since the room provides raw stats
            to your characters, it affects their power too.
          </li>
          <li>
            <strong>Bonds</strong> - bonds give raw stats which also increase
            your power, so go and talk to your characters!
          </li>
        </ul>
        <p>
          Another tip we can give you is to don't be afraid of using characters
          that we rated lower, but you received one or more dupes for, over the
          higher rated but with a single dupe. The power gained from limit
          breaking might be enough to push you over the recommended power and
          allow you to clear the stage.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesStagePenaltyPage;

export const Head: React.FC = () => (
  <Seo
    title="Stage penalty | NIKKE | Prydwen Institute"
    description="What is the Stage penalty system and how to abuse it"
  />
);
